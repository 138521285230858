@font-face {
  font-family: "ProximaNovaRegular";
  src: url("../font/ProximaNovaRegular.eot");
  src: local("ProximaNovaRegular"),
    url("../font/ProximaNovaRegular.woff") format("woff"),
    url("../font/ProximaNovaRegular.ttf") format("truetype");
}
.fontsforweb_fontid_58703 {
  font-family: "ProximaNovaRegular" !important;
}

@font-face {
  font-family: "Gilroy-Bold ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Bold ☞"), url("../font/Gilroy-Bold.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Heavy ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Heavy ☞"), url("../font/Gilroy-Heavy.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Light ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Light ☞"), url("../font/Gilroy-Light.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Medium ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Medium ☞"),
    url("../font/Gilroy-Medium.woff") format("woff");
}

@font-face {
  font-family: "Gilroy-Regular ☞";
  font-style: normal;
  font-weight: normal;
  src: local("Gilroy-Regular ☞"),
    url("../font/Gilroy-Regular.woff") format("woff");
}
