html {
  overflow-x: hidden;
}
a {
  text-decoration: none !important;
  list-style-type: none;
  color: #1b62a1;
}
body {
  font-family: "ProximaNovaRegular";
  padding: 0;
  margin: 0;
}
p {
  font-family: "ProximaNovaRegular";
  font-size: 18px;
  color: #222326;
  line-height: 30px;
  text-align: left;
  padding-top: 15px;
}
.paraalign {
  text-align: justify;
}
.yoo {
  color: #1b62a1;
  font-weight: 600;
}
.foo {
  color: #66b3d8;
  font-weight: 600;
}
h3 {
  font-size: 20px;
}
.paddinglr {
  padding-left: 0;
  padding-right: 0;
}
.content_font {
  font-family: "ProximaNovaRegular";
  font-size: 16px;
  font-style: 700;
  color: #0a4f8d;
}
.logopdng {
  padding-top: 0px;
}
.navbar-light .navbar-nav .nav-link {
  font-family: "ProximaNovaRegular";
  font-size: 16px;
  color: #0e70c7;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
  font-weight: 600;
  letter-spacing: 0.6px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: 0.5rem;
  font-family: "ProximaNovaRegular";
  font-size: 18px;
}
.header {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: auto;
  margin-top: 0px;
  z-index: 9999;
  margin-top: -1px;
  -webkit-box-shadow: 0 3px 3px -3px #d5d5d5;
  -moz-box-shadow: 0 3px 3px -3px #d5d5d5;
  box-shadow: 0 3px 3px -3px #d5d5d5;
}
/* .header{
    font-family: 'ProximaNovaRegular';
    font-size:18px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color:#fff;
    border-bottom: solid 1px #e7e7e7;   
} */
.logo {
  padding: 0px;
}
.navmenu {
  padding-top: 20px;
}
.banner {
  background: url(/assets/banner.jpg) no-repeat;
  background-size: contain;
  background-position: top;
  background: cover;
  min-height: 650px;
}
.bannertext {
  font-family: "ProximaNovaRegular";
  font-size: 70px;
  text-align: left;
  font-weight: 700;
  line-height: 70px;
  padding: 30px;
  color: #fff;
  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  border: 1px solid #e4e4e4;
  background-color: #1b62a1;
  margin-top: 55px;
  margin-left: 30px;
}
.bannertext1 {
  font-size: 30px;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  color: #515151;
  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  border: 1px solid #66b3d8;
  margin-left: 30px;
}
.about_ps {
  position: relative;
  left: 50%;
  top: 0px;
  text-align: center;
}
.aboutusbg {
  background: url(/assets/aboutbg.jpg) no-repeat;
  /* background-size:contain; */
  /* background-position:top; */
  background: cover;
  width: 100%;
  padding-top: 60px;
  min-height: 700px;
}
.react-tabs {
  margin-top: 80px;
}
.heading1,
.heading_testiml {
  font-family: "ProximaNovaRegular";
  font-size: 25px;
  font-weight: 700;
  text-align: left;
  padding-top: 0px;
  line-height: 25px;
  padding-left: 10px;
}
.topding {
  padding-top: 50px;
  padding-bottom: 50px;
}
.headingAbout,
.headingAbout_In {
  padding-left: 16px;
  font-size: 50px;
  color: #4c4c4c;
  text-align: left;
  font-weight: bold;
  font-family: "ProximaNovaRegular";
  text-transform: uppercase;
}
.headingAbout1,
.headingAbout1_In {
  color: #ff8873;
  font-size: 50px;
  text-align: left;
  font-weight: bold;
  font-family: "ProximaNovaRegular";
  text-transform: uppercase;
}
.headingAbout_In {
  font-size: 30px;
  padding-left: 0;
  padding-top: 8px;
}
.headingAbout1_In {
  font-size: 30px;
  padding-top: 8px;
  padding-left: 8px;
}
.heading2 {
  color: #5db4de;
  font-size: 23px;
  font-family: "ProximaNovaRegular";
}
.heading3 {
  color: #0e70c7;
  font-size: 25px;
  font-family: "ProximaNovaRegular";
  text-align: left;
  padding: 20px;
}
.heading_testiml {
  color: #fff;
}
.iconbox {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  text-align: center;
  border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 50px 50px 50px 50px;
  -webkit-border-radius: 100px 100px 100px 100px;
  /* border: 2px solid #66b3d8; */
}
.floatleft {
  float: left;
}
.floatright {
  float: right;
}
.vision {
  font-size: 18px;
  color: #003a78;
  text-align: center;
  padding: 0px;
  margin-top: 5px;
}
.liststyled ul {
  padding: 0;
  margin: 15px 0 0 25px;
  text-align: left;
}
.liststyled ul li {
  padding: 10px 0 0 5px;
  margin: 0;
  list-style-type: none;
  text-align: left;
}
.okicon {
  color: #25b8ff;
  font-size: 20px;
  margin-right: 5px;
}
.hdr1_pdng {
  padding-left: 0px;
}
.paragraphblue {
  font-size: 23px;
  color: #1b62a1;
  line-height: 40px;
  text-align: left;
  padding-top: 20px;
  padding-right: 50px;
}
.paragraphgray {
  font-size: 20px;
  color: #202227;
  line-height: 35px;
  text-align: left;
  padding-top: 8px;
  padding-right: 50px;
}
.explore {
  font-size: 18px;
  color: #1b62a1;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 10px;
  text-decoration: underline;
}
.roundedbox,
.roundedbox1,
.roundedbox2,
.roundedbox3 {
  margin: 20px 5px 5px 5px;
  width: 140px;
  height: 140px;
  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  background-color: #1b62a1;
  -webkit-box-shadow: 0px 3px 5px 0px rgba(117, 117, 117, 1);
  -moz-box-shadow: 0px 3px 5px 0px rgba(117, 117, 117, 1);
  box-shadow: 0px 3px 5px 0px rgba(117, 117, 117, 1);
  /* cursor: pointer;   */
}
.roundedbox1 {
  background-color: #fff;
}
.roundedbox2 {
  background-color: #5db4de;
}

.roundedbox3 {
  border: solid 2px #1b62a1;
  background: none;
}
.boxpdng {
  margin-top: 30px;
}
.boxpdng1 {
  margin-top: 80px;
}
.boxpdng4 {
  margin-top: 130px;
}

.square,
.square1 {
  display: flex;
  justify-content: center;
  align-content: center;
  animation: shimmy 3s infinite;
  animation-direction: alternate;
}
.square1 {
  animation: shimmy1 5s infinite;
  animation-direction: alternate;
}

@keyframes shimmy {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(10px, 20px);
  }
}
@keyframes shimmy1 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(40px, 0px);
  }
}

.element {
  display: inline-block;
  animation: skew 2s infinite;
  transform: skew(5deg);
  animation-direction: alternate;
  opacity: 0.7;
}

@keyframes skew {
  0% {
    transform: skewX(20deg);
  }
  100% {
    transform: skewX(-20deg);
  }
}

.float_left {
  float: left;
}
.float_right {
  float: right;
}
.clientNo,
.clientNo1,
.clientNo2,
.clientNo3,
.clientNo4,
.clientNo5,
.clientNo6,
.clientNo7 {
  font-family: "ProximaNovaRegular";
  font-size: 30px;
  color: #1b62a1;
  text-align: right;
  padding-right: 16px;
  padding-top: 8px;
}
.clientNo1,
.clientNo3,
.clientNo4,
.clientNo5,
.clientNo6,
.clientNo7,
.clientNo03 {
  font-family: "ProximaNovaRegular";
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  padding-top: 35px;
  padding-left: 10px;
}
.clientNo2 {
  color: #fff;
  text-align: right;
  font-family: "ProximaNovaRegular";
}
.clientNo3,
.clientNo03 {
  color: #fff;
  text-align: left;
  font-family: "ProximaNovaRegular";
  font-size: 15px;
}
.clientNo4 {
  color: #4ca8d4;
  text-align: right;
  font-size: 35px;
  padding-top: 30px;
  padding-left: 20px;
  font-family: "ProximaNovaRegular";
}
.clientNo5 {
  color: #4ca8d4;
  text-align: left;
  font-family: "ProximaNovaRegular";
}
.clientNo6 {
  color: #383a3b;
  text-align: right;
  font-size: 35px;
  padding-top: 30px;
  padding-left: 20px;
}
.clientNo7 {
  color: #383a3b;
  text-align: left;
}
.whatwedo_hdr {
  background-color: #1b62a1;
  padding: 8px 5px 8px 15px;
  color: #fff;
  font-size: 26px;
  font-family: "ProximaNovaRegular";
  letter-spacing: 0.5px;
  text-align: left;
  border-radius: 10px 0px 0px 10px;
  -moz-border-radius: 10px 0px 0px 10px;
  -webkit-border-radius: 10px 0px 0px 10px;
}
.whatwedo_hd1 {
  background-color: #eeeeee;
  font-size: 18px;
  font-family: "ProximaNovaRegular";
  color: #595959;
  padding: 12px 5px 5px 15px;
  text-align: left;
  border-radius: 0px 10px 10px 0px;
  -moz-border-radius: 0px 10px 10px 0px;
  -webkit-border-radius: 0px 10px 10px 0px;
}
.lineblue {
  width: 45px;
  height: 6px;
  float: left;
  margin-top: 17px;
  margin-right: 5px;
  background-color: #0a4f8d;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
}
.content_mrg,
.content_mrg1 {
  margin-top: 100px;
  padding-left: 60px;
  padding-right: 60px;
}
.content_mrg1 {
  margin-top: 0px;
}
.card-title {
  font-size: 25px !important;
  color: #1b62a1 !important;
}
.whatwedo_pdng {
  margin-top: 50px;
  margin-bottom: 50px;
}
.Design_tpdng {
  margin-top: 35px;
  padding: 5px !important;
}
.Build_tpdng {
  margin-top: 100px;
  padding: 5px !important;
}
.Scale_tpdng {
  margin-top: 30px;
  padding: 5px !important;
}
.whatwedo_subp {
  padding-top: 50px;
  padding-bottom: 30px;
}
.whatwedo_hdrsub,
.whatwedo_hdrsub1 {
  padding-top: 50px;
  font-size: 20px;
  color: #1b62a1;
  font-family: "ProximaNovaRegular";
  text-align: left;
}
.whatwedo_hdrsub1 {
  text-align: right;
}
.Secure_tpdng {
  margin-top: 90px;
  padding: 5px !important;
}
.cardhdr {
  text-align: left !important;
  color: #202227;
  font-family: "ProximaNovaRegular";
  font-size: 18px;
}
.card-text {
  color: #222326;
  font-family: "ProximaNovaRegular";
  font-size: 18px;
}
.cardhdr a {
  text-align: left !important;
}
.card {
  border: 0 !important;
  text-align: left !important;
  padding-left: 20px;
}
.Ourservices {
  margin-top: 50px;
  margin-bottom: 50px;
}
.cardbr {
  height: 100px;
  width: 10px;
  border-left: solid 4px #ccc;
  float: left;
  font-size: 20px;
  color: #b8b8b8;
}
.cardbr1 {
  float: right;
}
.cardpdng {
  padding-left: 50px;
  padding-top: 75px;
}
.cardpdng_img {
  padding-left: 20px;
  padding-top: 75px;
  text-align: left;
}
/* .image {    
    transition: .5s ease;
    backface-visibility: hidden;    
  }
  .cardpdng:hover .image {
    opacity: 0.5;
  }
   */
/* .containerimg {
    position: relative;  
  }
  
  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 60%;
    left: 60%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .containerimg:hover .image {
    opacity: 0.4;
  }
  
  .containerimg:hover .middle {
    opacity: 1;
  }
  
  .text {
    background-color: #1b62a1;
    color: white;
    font-size: 16px;
    padding: 5px 32px;
    border-radius: 6px;
  } */

.icon1 {
  font-size: 35px;
  color: #1b62a1;
  text-align: left;
  font-weight: lighter;
  margin-bottom: 15px;
}
.readmore {
  font-size: 14px;
  margin-left: 5px;
  margin-top: 5px;
  font-weight: normal !important;
}
.readmore a {
  font-weight: normal !important;
}
.testimonialsbg {
  background: url(/assets/testimonials.png) no-repeat;
  background-size: contain;
  background-position: center;
  background: cover;
  min-height: 650px;
  padding-top: 50px;
}
.centerpdng {
  padding-left: 50px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.testimonialspanel {
  padding: 30px 10px 30px 10px;
  margin-top: 0px;
  background-color: #003a78;
  border-radius: 15px;
  /* background: url(/assets/testbg.png) no-repeat; */
  background-size: contain;
  background-position: center;
  background: cover;
  min-height: 465px;
}
.imgicon {
  width: 180px;
  height: 180px;
}
.imgslide {
  text-align: left;
  margin-top: -35%;
}
.aws-sld__wrapper {
  overflow: hidden;
  height: 100%;
}
.aws-sld__content {
  background-color: transparent;
  padding: 80px 10px 10px 50px;
  color: #fff;
  overflow: hidden;
  display: flex;
  text-align: left;
  align-items: left;
  justify-content: center;
}
.aws-sld__controls button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--control-button-width);
  height: var(--control-button-height);
  position: absolute;
  z-index: 10;
  top: calc(50% - (0.5 * var(--control-button-height)));
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
}
.aws-sld__controls__arrow-left,
.aws-sld__controls__arrow-right {
  width: 32px;
  height: 20px;
  color: #fff !important;
  position: relative;
  display: block;
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s;
  transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out;
  transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out,
    -webkit-transform 0.2s ease-out 0.125s;
}
.aws-sld {
  --organic-arrow-thickness: 4px;
  --organic-arrow-height: 40px;
  --slider-height-percentage: 68%;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-opacity: 0.5;
  --control-button-hover-opacity: 0.75;
  --control-button-background: transparent;
  --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
  --slider-transition-duration: 575ms;
  --organic-arrow-color: #6a6a6a;
  --organic-arrow-border-radius: 0;
  --control-bullet-color: #6a6a6a;
  --control-bullet-active-color: #6a6a6a;
  --content-background-color: #2f2f2f;
  max-width: 100%;
}
button:hover {
  color: #ffffff;
  background: #1768b5 !important;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}
.profilepdng {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 9999;
  margin-top: -10px;
}
.pos {
  z-index: -1;
}
.pl0 {
  padding-left: 0;
}
.trustedbyindpanel {
  margin-top: 30px;
  padding-left: 0px;
  background-color: #fff;
  border: solid 1px #b8ddff;
  /* background: url(/assets/testimonials-logosbg.png) no-repeat; */
  background-size: contain;
  background-position: center;
  background: cover;
  min-height: 465px;
  border-radius: 60px 20px 20px 20px;
  -moz-border-radius: 60px 20px 20px 20px;
  -webkit-border-radius: 60px 20px 20px 20px;
}
.fold {
  position: relative;
  padding: 0px;
}
.fold::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  border-style: solid;
  border-width: 0 0px 50px 50px;
  border-color: #1b62a2 #fff;
  transition: all ease 0.5s;
  border-radius: 0px 0px 5px 0px;
  /* -webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.75); */
}
.headertm {
  font-size: 23px;
  color: #fff;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0.5px;
}
.media_pdng {
  padding-top: 30px;
}
.profileimg {
  width: 170px;
  height: 170px;
  padding-top: 20px;
  padding-left: 5px;
  background: -webkit-linear-gradient(
    90deg,
    rgb(0, 68, 153) 0%,
    rgb(35, 175, 255) 80%
  );
  background: -o-linear-gradient(
    90deg,
    rgb(0, 68, 153) 0%,
    rgb(35, 175, 255) 80%
  );
  background: -moz-linear-gradient(
    90deg,
    rgb(0, 68, 153) 0%,
    rgb(35, 175, 255) 80%
  );
  background: linear-gradient(90deg, rgb(0, 68, 153) 0%, rgb(35, 175, 255) 80%);
  -webkit-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.75);
  border-radius: 100px;
  box-shadow: #212527 4px;
  position: absolute;
  top: 5px;
  left: 10px;
  display: inline-block;
  z-index: 990;
}
.img-thumbnail {
  padding: -0.75rem !important;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.testimonial_hdr1,
.testimonial_hdr2,
.headerthanks {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  text-align: left;
  padding: 20px 0 10px 200px;
}
.testimonial {
  padding-top: 10px;
}
.testimonial_hdr2 {
  font-weight: lighter;
  font-size: 16px;
  padding-left: 200px;
  padding-top: 0;
  padding-bottom: 0px;
}
.headerthanks {
  font-size: 20px;
  padding-left: 0px;
  padding-top: 30px;
}
.testimonial_p,
.testimonial_sub {
  line-height: 20px;
  font-weight: lighter;
  font-size: 16px;
  color: #fff;
  text-align: left;
  line-height: 25px;
  padding: 70px 50px 10px 50px;
}
.testimonial_sub {
  color: #2f2f2f;
  padding-top: 0px;
  padding-left: 10px;
  margin: 0;
}
.trustedbyind_hdr,
.trustedbyind_hdr1 {
  font-size: 30px;
  font-family: "ProximaNovaRegular";
  text-align: left;
  line-height: 40px;
  padding-top: 0px;
  padding-left: 23px;
}
.trustedbyind_hdr1 {
  color: #363636;
  padding-top: 40px;
  padding-left: 30px;
}
.trustDivpdng {
  padding: 80px 50px 50px 50px;
  min-height: 500px;
}
.NewsDivbg {
  background-color: #135ca0;
  padding: 50px 50px 50px 200px;
  background: url(/assets/newsbg.png) no-repeat;
  background-position: right;
  background: cover;
  min-height: 544px;
}
.marqueepdng {
  text-align: center;
}
.chalkcoutureicon {
  padding-top: 10px;
}
.footerlogo {
  padding-top: 5px;
  padding-bottom: 30px;
}
.industryExbg {
  background-color: #f3f3f3;
  margin-bottom: 20px;
}
.newslp {
  padding-left: 0;
}
.Industry_div {
  float: left;
  width: 300px;
}
.Industry_logo {
  width: 250px;
  height: 60px;
  padding-left: 25px;
}
.Industry_lpdng {
  padding-top: 0 !important;
}
.Industry_div1 {
  float: left;
  width: 100px;
}
.Industry_row {
  padding-left: 25px;
}
.Industry_subh,
.Industry_subh1,
.Industry_p,
.Industry_subh2 {
  font-size: 18px;
  color: #fff;
  text-align: left;
  /* padding-top: 20px; */
  font-weight: lighter;
  font-family: "ProximaNovaRegular";
  padding-top: 8px;
}
.Industry_subh1 {
  color: #494d51;
  font-size: 20px;
  padding-left: 25px !important;
  font-family: "ProximaNovaRegular";
}
.Industry_subh2 {
  padding-top: 5px;
  color: #004f96;
  padding-left: 25px !important;
}
.Industry_p {
  font-size: 18px;
  color: #003a78 !important;
  text-align: left;
}
.Industry_des1_1,
.Industry_des1_3,
.Industry_des1_5 {
  font-size: 18px;
  color: #003a78 !important;
  text-align: left;
}
.Industry_des1_2,
.Industry_des1_4,
.Industry_des1_6 {
  font-size: 18px;
  color: #25b8ff !important;
  text-align: right;
}
.Industry_p1 {
  color: #25b8ff !important;
  font-size: 20px;
  text-align: right;
}
.list-group {
  font-family: "ProximaNovaRegular";
  font-size: 25px;
  padding-top: 20px;
}
.list-group-item {
  padding: 0.5rem 0.25rem !important;
  background: none;
  border: none;
}
.Industry_smallh {
  font-size: 16px;
  color: #fff;
  text-align: left;
  padding-top: 3px;
  font-family: "ProximaNovaRegular";
}
.list-group-item {
  color: #595959;
  font-size: 18px;
}
.ind_pdng {
  padding-top: 20px;
}
.list-group-item {
  padding: 0.2rem 0.25rem;
  background: none;
  border: none;
}
.badge {
  font-size: 18px;
  color: #1b62a1;
}
.news_p {
  font-size: 18px;
  color: #fff;
  text-align: left;
  padding-top: 30px;
  line-height: 30px;
}
.readmore_r {
  font-size: 16px;
  color: #fff;
  text-align: left;
  padding-top: 50px;
  padding-right: 20px;
}
.readmore_r a {
  color: #fff;
  text-decoration: none;
}
/*------Footer---------*/

.footerbg {
  background-color: #1b62a1;
  padding: 50px 0;
}
.caretojoin {
  font-family: "ProximaNovaRegular";
  font-size: 70px;
  color: #fff;
  text-align: center;
  padding: 10px 0;
}
.subhdr_blue,
.subhdr_wt {
  font-size: 25px;
  color: #aee9ff;
  text-align: center;
  padding: 20px 0;
  font-weight: lighter;
}
.subhdr_wt {
  color: #fff;
  padding: 0px 0;
  letter-spacing: 1px;
}
.subhdr_wt1 {
  font-size: 20px;
  color: #fff;
  text-align: center;
  padding: 30px 0;
  letter-spacing: 1px;
}
.Contactbannerhdr,
.Contactbannerhdr1,
.Contactbannerhdr2,
.Contactbannerhdr4 {
  font-size: 50px;
  font-family: "ProximaNovaRegular";
  color: #fff;
  text-align: left;
  padding: 80px;
  letter-spacing: 1px;
  text-shadow: 1px 1px #212527;
}
.Contactbannerhdr1 {
  color: #004f96;
  padding: 0;
  margin: 0;
}
.Contactbannerhdr2 {
  color: #25b8ff;
  padding: 0;
  margin: 0;
}
.Contactbannerhdr3 {
  color: #212527;
  padding: 0;
  margin: 0;
}
.Contactbannerhdr4 {
  color: #fff;
  padding: 0;
  margin: 0;
}
.applyNow {
  color: #ffc601;
  font-size: 35px;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 30px;
  font-weight: lighter;
  letter-spacing: 1px;
}
.applyNow a {
  font-size: 35px;
  color: #ffc601;
  text-decoration: underline !important;
  text-decoration-style: wavy !important;
}
.footer_br {
  background-color: #0a4f8d;
  height: 1px;
}
.footer_br1 {
  background-color: #266fb0;
  height: 1px;
}
/* .nav a{
    color: #fff;
    text-decoration: none;
    text-align: center;
    padding-bottom: 30px;
} */
.footercopy {
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding-top: 30px;
  letter-spacing: 1px;
}
.footercopy a {
  color: #fff;
  font-size: 20px;
  padding: 0 10px;
}
.fontb {
  font-family: "ProximaNovaRegular";
  font-size: 16px;
  color: #595959;
}
/* @font-face {
    font-family: 'Khand Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Khand Regular'), url('../fonts/Khand-Regular.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Khand Light';
    font-style: normal;
    font-weight: normal;
    src: local('Khand Light'), url('../fonts/Khand-Light.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Khand Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Khand Medium'), url('../fonts/Khand-Medium.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'Khand SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Khand SemiBold'), url('../fonts/Khand-SemiBold.woff') format('woff');
    }
    
    
    @font-face {
    font-family: 'ProximaNovaRegular';
    font-style: normal;
    font-weight: normal;
    src: local('ProximaNovaRegular'), url('../fonts/Khand-Bold.woff') format('woff');
    }  */
.ScrollUpButton__Container {
  background-color: #5db4de !important;
  border-radius: 100px !important;
  position: fixed;
  bottom: 0px !important;
  width: 50px !important;
  height: 50px !important;
  /* transition: opacity 0.5s ease-in-out 0s, right; */
  cursor: pointer;
  opacity: 1;
  float: right;
  /* right: 75px !important; */
  /* bottom: 20px !important; */
  text-align: center;
  z-index: 1000;
  fill: #fff !important;
  margin-bottom: 10px;
  padding-top: 12px !important;
  /* padding-bottom: 8px !important; */
  padding-left: 15px !important;
  padding-right: 15px !important;
  border: none;
  outline: 0px;
}


.AnyClassForTransition {
  right: 86px !important;
  margin-bottom: 21px;
}

/*----------Slider Css-------------*/

button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: none !important;
  cursor: pointer;
  color: #fff;
  letter-spacing: 1px;
  background: #4ca8d4;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 10px 30px;
  padding-left: 75px;
  padding-right: 75px;
  float: left;
  margin-top: 15px;
  outline: none !important;
}

button:hover {
  outline: none;
  color: #ffffff;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.slide h1 {
  transition: all 0.3s ease;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.slide button {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  font-family: "ProximaNovaRegular";
  font-size: 16px;
}

.slide p {
  transition: all 0.3s ease;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  opacity: 0;
  margin-bottom: 5px;
}

.slide section * {
  transition: all 0.3s ease;
}

.slide section img {
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  opacity: 0;
}

.slide section span {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  opacity: 0;
}

.slide section span strong {
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
  opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.slide.animateOut h1 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.slide.animateOut p {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.slide.animateOut section span {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.slide.animateOut section span strong {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-wrapper {
  position: relative;
  height: 70vh;
  overflow: hidden;
  margin-top: 68px;
}
.slider-wrapper2 {
  position: relative;
  height: 35vh;
  overflow: hidden;
  margin-top: 10px;
}
.slider-wrapper3 {
  position: relative;
  height: 30vh;
  overflow: hidden;
  margin-top: 5px;
}

.slide {
  height: 70vh;
  background-size: cover !important;
  padding-top: 0px;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0))
  );
  background: linear-gradient(transparent, rgba(0, 0, 0, 0));
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 40%;
  /* transform: translateY(-50%); */
  z-index: 10;
  background: url("../../assets/arrowright.png") no-repeat center center;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  cursor: pointer;
  /* margin-left: -10px; */
}

.previousButton:hover,
.nextButton:hover {
  background: url("../../assets/arrowright.png") no-repeat center center;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(0% + 0px));
  transform: rotate(180deg) translateY(calc(0% + 0px));
}

.previousButton:hover {
  left: 0px;
}

.nextButton {
  position: absolute;
  top: 40%;
  right: 0;
}

.nextButton:hover {
  right: 0px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 60px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 45%;
  left: 65%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
  /* position: absolute;
            top: 30%;
            left: 70%; */
}

.slider-content .inner h1 {
  font-family: "ProximaNovaRegular";
  font-size: 50px;
  text-align: left;
  line-height: 60px;
  letter-spacing: 0;
  font-weight: bold;
  padding: 10px 10px 0px 0px;
  color: #1b62a1;
  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  /* border: 1px solid #e4e4e4;
    background-color: #1b62a1; */
  max-width: 600px;
}
.h1_hdr {
  font-family: "ProximaNovaRegular";
  font-size: 50px !important;
  text-align: left;
  line-height: 60px;
  letter-spacing: 0;
  font-weight: bold;
  padding: 10px 10px 10px 0px;
  color: #1b62a1;
}

.slider-content .inner p {
  font-size: 23px;
  text-align: left;
  font-weight: bold;
  padding: 0px 5px 5px 15px;
  color: #1b62a1;
  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  /* border: 1px solid #66b3d8;
    background-color: #fff; */
  max-width: 750px;
  /* background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(27, 98, 161, 0.9)));
    background: linear-gradient(transparent, rgba(27, 98, 161, 0.9)); */
}

.slider-content section {
  position: absolute;
  bottom: 0px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

/*---------------------------------------------*/

.react-tabs__tab--selected {
  background: #1b62a1 !important;
  border: 0;
  color: #fff;
  border-radius: 5px 5px 0 0;
  text-align: left;
  outline: none;
}
.react-tabs__tab {
  display: inline-block;
  border: 0px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 20px 6px 10px;
  cursor: pointer;
  background-color: #dadada;
  border-radius: 6px 6px 0px 0px;
  -moz-border-radius: 6px 6px 0px 0px;
  -webkit-border-radius: 6px 6px 0px 0px;
}
.react-tabs__tab-list {
  margin: 0 0 0px;
  padding: 0;
  text-align: left;
  outline: 0;
  border: 0;
  color: #616467;
}

/*-----------------------------------------*/

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.dropdown-menu.show {
  display: block;
  color: #1b62a1;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 15rem;
  padding: 0rem 0;
  margin: 0rem 0 0;
  font-family: "ProximaNovaRegular";
  color: #1b62a1;
  text-align: left;
  list-style: none;
  background-color: #eaf5fb;
  background-clip: padding-box;
  border: 1px solid #e2eef5;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 0.5rem;
  clear: both;
  font-size: 14px;
  font-family: "ProximaNovaRegular";
  color: #1b62a1;
  background-color: transparent;
  border: 0;
  margin-top: 2px;
  letter-spacing: 0.5px !important;
  text-transform: capitalize;
  border-radius: 0em !important;
  border-bottom: solid 1px #76a9d6;
}
.dropdown-item:hover {
  color: #fff !important;
  background-color: #1b62a1 !important;
  border-radius: 0em !important;
}
.dropdown-item:focus {
  color: #fff !important;
  background-color: #1b62a1 !important;
  border-radius: 0em !important;
}

/*-------------Contact Us---------------------*/

.contactus {
  background: url(/assets/contactbg.jpg) no-repeat;
  background-size: cover;
  background-position: top;
  background: cover;
  min-height: 670px;
}
.getintbg,
.getintbg1 {
  background-color: #fff;
  padding: 0px 30px 0px 0px;
  margin-top: 50px;
  min-height: 400px;
  margin-bottom: 0px;
  opacity: 1;
}
.contactfrom {
  background-color: #fff;
  padding: 0px 30px 30px 30px;
  margin-top: 0px;
  margin-bottom: 50px;
  opacity: 1;
}
.getintbg1 {
  margin-top: 20px;
  padding-left: 0px;
}
.Getinhdr {
  color: #1b62a1;
  font-size: 40px;
  text-align: left;
  font-family: "ProximaNovaRegular";
}
.submitbtn {
  background-color: #003a78;
  border: none;
  width: 100%;
  font-size: calc((-0.2 * (1em - 13px) - -2em - 13px));
  cursor: pointer;
  color: #fff;
  border-radius: 6px;
  line-height: 30px;
  margin-top: 10px;
}
.formpdng {
  margin-top: 20px;
}
.form-group .form-control {
  font-family: "ProximaNovaRegular";
  padding-left: 15px;
  margin-bottom: 20px;
  background-color: transparent;
  background-clip: unset;
  border: none;
  border-radius: 0;
  font-size: calc((-0.2 * (1em - 13px) - -2em - 13px));
  color: #363636;
  line-height: 1;
  border-bottom: 1px solid #c9c9c9;
  padding: 10px 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.contactsubh {
  text-align: left;
  color: #595959;
  font-size: 18px;
}
.addressbg {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #fff;
  min-height: 300px;
}
.faicons {
  font-size: 50px;
  color: #0e5b9f;
  text-align: left;
}
.address_hdr {
  font-size: 23px;
  text-align: left;
  font-weight: 600;
  color: #004f96;
}
.address_hdr1 {
  font-size: 18px;
  color: #004f96;
  font-weight: 600;
  text-align: left;
  padding-top: 10px;
}
.mailid {
  letter-spacing: 1px;
}
.address_p {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
.flagdiv {
  float: left;
  padding-right: 10px;
}
.addresstopp {
  padding-top: 40px;
  padding-left: 0px;
}
.adrtop30 {
  padding-top: 30px;
}
.adrbtm15 {
  padding-bottom: 15px;
}
/* .addrlp{
    padding-left: 30px;
} */

/*-------About Effect css----------*/

.aboutbg,
.articleseventsbg,
.blogbg,
.ourdnabg,
.practiceareas,
.daa,
.Bfsibg,
.Tpcbg,
.LifeSciencesbg {
  background: url(/assets/aboutusbg.jpg) no-repeat;
  background-size: cover;
  background-position: top;
  background: cover;
  min-height: 550px;
}
.articleseventsbg {
  background: url(/assets/articlesevents.jpg) no-repeat;
}
.blogbg {
  background: url(/assets/blog.jpg) no-repeat;
}
.practiceareas {
  background: url(/assets/practiceareas.jpg) no-repeat;
}
.ourdnabg {
  background: url(/assets/ourdna.jpg) no-repeat;
}
.daa {
  background: url(/assets/DistributedAgileAugmentation.jpg) no-repeat;
}
.Bfsibg {
  background: url(/assets/Bfsi.jpg) no-repeat;
}
.Tpcbg {
  background: url(/assets/TechnologyProjectConsulting.jpg) no-repeat;
}
.LifeSciencesbg {
  background: url(/assets/LifeSciences.jpg) no-repeat;
}
.About_hdr,
.About_hdr1,
.About_hdr2,
.About_hdr3 {
  font-size: 40px;
  /* font-family: 'Khand SemiBold'; */
  color: #003a78;
  text-align: left;
  padding: 100px 10px 10px 0;
  font-weight: lighter;
  letter-spacing: 1px;
  text-shadow: 1px 1px #212527;
}
.About_hdr1 {
  color: #0e70c7;
  padding: 0;
  margin: 0;
}
.About_hdr2 {
  color: #25b8ff;
  padding: 0;
  margin: 0;
}
.About_hdr3 {
  color: #212527;
  padding: 0;
  margin: 0;
}
.aboutContent {
  min-height: 500px;
  padding: 50px;
  background-color: #fff;
}
.innerContent {
  min-height: 500px;
  padding: 80px 50px 50px 50px;
  background-color: #fff;
}
.activeclients_p {
  color: #fff;
  padding: 20px;
  background-color: #004f96;
  font-family: "ProximaNovaRegular";
  border-radius: 6px 6px 6px 6px;
  -moz-border-radius: 6px 6px 6px 6px;
  -webkit-border-radius: 6px 6px 6px 6px;
}
.testimonials_bg {
  background: url(/assets/testbg.png) no-repeat;
  background-size: cover;
  background-position: top;
  background: cover;
  min-height: 465px;
}
.__react_component_tooltip {
  font-family: "ProximaNovaRegular";
  background-color: #004f96;
  padding-bottom: 25px;
  font-size: 16px;
  width: 400px;
  opacity: 1;
  text-align: left;
  -moz-box-shadow: 0 0 5px #888;
  -webkit-box-shadow: 0 0 5px#888;
  box-shadow: 0 0 5px #888;
  border-radius: 20px 20px 20px 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  border: 1px solid #66b3d8;
  transition: all 0.3s ease;
}
/*--------------------*/
.modal-body {
  padding: 0 1rem 0rem 1rem !important;
}
.modal-header {
  font-size: 18px;
  color: #0e5b9f;
  /* background-color: #e0e6f2;
    color: #0e5b9f; */
}
/* .modelhdr{
    color: #fff;
    background-color: #50acd8;
} */
.popuppara,
.popuppara1 {
  font-size: 16px;
  line-height: 26px;
  text-align: justify;
  color: #1b62a1;
  padding: 10px 30px 30px 30px;
}
.popuppara1 {
  text-align: justify;
  color: #3c3c3c;
  padding-bottom: 10px;
}
/* .boxblue{
      color: #0e5b9f;
      text-align: center;
      background-color: #f4f4f4;
      padding: 8px 15px 8px 15px;
      display: block;
      float: left;
      font-size: 16px;
      margin: 2px;
      border-radius: 10px;
      font-weight: 800;      
      border: solid 1px #bcbcbc;
  } */
.blueboxL {
  background-color: #3da5d6;
  padding: 10px;
  color: #fff;
}
.blueboxR {
  background-color: #fff;
  padding: 0px;
}
.popupli {
  list-style-type: none;
  text-align: left;
  color: #0e5b9f;
}
.popuplist {
  margin-left: 0px;
  padding: 0 0 0 15px;
  text-align: left;
  list-style-type: none;
}
.popuplist ul {
  padding: 0;
  margin: 0;
  text-align: left;
  list-style-type: none;
  text-decoration: none;
}
.popuplist ul li {
  text-align: left;
  padding: 5px;
  border-bottom: solid 1px #52b3e1;
}
.popuplist ul li a {
  text-align: left;
  padding: 5px;
  color: #fff;
}
.popuplist ul li a:hover {
  color: #ffd800;
}
.okicon,
.checkicon {
  font-size: 12px;
  color: #fff;
  margin-right: 10px;
}
.checkicon {
  color: #0e5b9f;
}
.detail-img {
  max-width: 100%;
}
.popupimg {
  padding-bottom: 70px;
}
.btntmng {
  margin-top: 15px;
  margin-bottom: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.Requestfrom {
  padding: 0 30px 30px 30px;
  margin-top: 0px;
  margin-bottom: 0px;
}

/*--------------*/

.clear {
  clear: both !important;
}
.paddingleft0 {
  padding-left: 0;
}
.paddingrt0 {
  padding-right: 0;
}
.hdrm {
  font-size: 50px;
  text-align: left;
  color: #4c4c4c;
  font-weight: bold;
}
.hdrm1 {
  font-size: 50px;
  text-align: left;
  color: #ff8873;
  font-weight: bold;
}
.industry_p50 {
  padding-top: 35px;
  height: 400px;
}
.paytopp {
  padding-top: 80px;
  padding-bottom: 100px;
}
.pay_pdng {
  padding-left: 60px;
  padding-right: 30px;
}
.blueline {
  height: 150px;
  float: left;
  padding: 0;
  width: 5% !important;
  border-left: solid 6px #66b3d8;
}
.paypara {
  font-size: 16px;
  text-align: left;
  padding-top: 1px;
  padding-right: 100px;
}
.modal-header .close:hover {
  color: #3da5d6 !important;
  border: none;
  border-radius: 0px !important;
  box-shadow: none !important;
  background-color: #fff !important;
}
.modal-header .close {
  font-size: 30px;
  padding: 0px !important;
  margin: 1px !important;
}
.monthlyhdr {
  color: #3da5d6;
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  padding-left: 15px;
}
.monthlydis {
  background-color: #3da5d6;
  color: #fff;
  padding: 3px 6px 3px 6px;
  font-size: 20px;
  text-align: left;
  margin-left: 15px;
}
.hpatop {
  padding-top: 20px;
}
.divtop {
  padding-top: 10px;
}
.box1,
.box2,
.box3,
.box4 {
  height: 200px;
  padding: 70px 0 0 10px;
  border-right: solid 1px #e0e0e0;
  border-bottom: solid 1px #e0e0e0;
}
.box2 {
  border-bottom: solid 1px #e0e0e0;
  border-right: 0;
}
.box3 {
  padding: 40px 0 0 10px;
  border-bottom: 0;
}
.box4 {
  padding: 40px 0 0 10px;
  border-bottom: 0;
  border-right: 0;
}

.iconimg {
  width: 120px;
  height: 120px;
  float: left;
}
.icontextdiv {
  float: left;
}
.payinnerpdng {
  padding-top: 50px;
}
.payh1 {
  color: #363636;
  font-size: 23px;
  text-align: left;
  font-weight: 600;
}
.payh2 {
  color: #36afd5;
  font-size: 23px;
  text-align: left;
  font-weight: 600;
}
.payh3 {
  color: #0e447e;
  font-size: 30px;
  text-align: left;
  font-weight: 600;
  padding-left: 5px;
}
.payh4 {
  color: #0e447e;
  font-size: 30px;
  text-align: left;
  font-weight: normal;
}
.Distributed_para {
  color: #266fb0;
  text-align: center;
  padding: 20px 50px 20px 50px;
}
.ousrser_img {
  padding-bottom: 50px;
}
.modal_header1 {
  background-color: #3da5d6 !important;
  color: #fff !important;
}
.lefthdrbluline,
.lefthdrbluline1,
.lefthdrbluline2 {
  width: 7px;
  height: 60px;
  float: left;
  margin-top: 10px;
  background-color: #3da5d6;
}
.lefthdrbluline1 {
  height: 190px;
}
.lefthdrbluline2 {
  height: 40px;
}
.para1 {
  font-size: 16px;
  color: #000000 !important;
  text-align: left;
  padding-top: 0;
  padding-right: 100px;
  text-transform: initial;
  font-weight: normal;
}
.righthdrdiv {
  float: left;
  padding-left: 20px;
}
.paddingtop {
  padding-top: 50px;
  padding-bottom: 0px;
}
.slider-wrapper11 > .previousButton {
  display: block;
  margin-left: -35px;
}
.slider-wrapper11 > .nextButton {
  display: block;
}
.bg-light {
  background-color: transparent !important;
}

/*--------------------*/

.ind_line {
  height: 115px;
  margin-top: 40px;
  margin-bottom: 50px;
  border-left: solid 6px #66b3d8;
}
.about_para {
  color: #383a3b;
  font-size: 18px;
  text-align: justify;
  padding: 10px 10px 0px 10px;
}
.containertab {
  padding: 0px 20px 10px 20px;
  margin: 0;
}
.tabcontenth {
  min-height: 200px;
}
.aboutlist ul {
  padding: 0;
  margin: 5px 0 0 15px;
  text-align: left;
  list-style-type: none;
  text-decoration: none;
}
.aboutlist ul li {
  padding: 10px 0 0 5px;
  margin: 0;
  list-style-type: none;
  text-decoration: none;
  text-align: left;
}
.abouticon {
  color: #25b8ff;
  font-size: 15px;
  margin-right: 5px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #62a8ff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.paddingt0 {
  padding-top: 0;
}
.btn-primary {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
}

.OurClients_bg {
  background: url(/assets/clients-bg.jpg) no-repeat;
  background-size: cover;
  background-position: top;
  background: cover;
  min-height: 350px;
  /* background-color: #f7f8fa; */
  padding: 30px 30px 100px 30px;
  margin-top: 0px;
}
.ourclient_hdr {
  font-size: 30px;
  text-align: center !important;
  padding-top: 15px;
  padding-bottom: 65px;
  font-weight: bold;
  color: #545454;
}
.ourclient_hdr1 {
  font-size: 30px;
  text-align: center !important;
  padding-top: 15px;
  padding-bottom: 65px;
  font-weight: bold;
  color: #00489e;
}
.clientLogo {
  padding: 5px;
}
.clientlogopdng {
  padding-top: 50px;
}
.review_bg {
  background: url(/assets/reviews-bg.jpg) no-repeat;
  background-size: cover;
  background-position: top;
  background: cover;
  min-height: 500px;
  padding: 30px 30px 30px 30px;
  margin-top: 0px;
  min-height: 420px;
  border-bottom: solid 5px #66b3d8;
}
.review_hdr {
  font-size: 30px;
  text-align: center !important;
  padding-top: 5px;
  padding-bottom: 30px;
  font-weight: bold;
  color: #00489e;
}
.Review_profileimg {
  width: 90px;
  height: 90px;
  padding-top: 0px;
  padding-left: 5px;
  position: absolute;
  top: -35px;
  left: -130px;
  /* display: inline-block; */
  z-index: 990;
}
.Review_profileimg1 {
  width: 90px;
  height: 90px;
  padding-top: 0px;
  padding-left: 5px;
  position: absolute;
  top: -35px;
  left: -260px;
  display: inline-block;
  z-index: 990;
}
.reviewLpdng {
  padding-left: 50px;
}
.reviewLpdng_0 {
  padding-left: 0px;
}
.reviews_hdr1 {
  font-size: 20px;
  color: #00489e;
  font-weight: 700;
  text-align: left;
  padding: 0px 0 10px 0px;
}
.reviews_hdr2 {
  color: #222326;
  font-weight: lighter;
  font-size: 16px;
  padding-left: 0px;
  padding-top: 0;
  font-weight: 600;
  padding-bottom: 0px;
  line-height: 16px;
}
.reviews_p,
.reviews_p0 {
  font-weight: lighter;
  font-size: 16px;
  color: #222326;
  text-align: left;
  line-height: 25px;
  padding: 10px 0px 10px 0px;
}
.reviews_p0 {
  padding: 10px 120px 10px 0px;
}
.reviews_p1 {
  padding: 10px 100px 2px 0px;
  font-weight: lighter;
  font-size: 16px;
  color: #222326;
  text-align: left;
  line-height: 25px;
}
.review_blueline {
  width: 10px;
  height: 100%;
  float: left;
  padding-left: 10px;
  border-left: solid 5px #50b0fe;
}

.arrow {
  text-align: center;
  margin: -55px 0 0 0;
  padding-left: 65px;
  z-index: 999;
  font-size: 25px;
  cursor: pointer;
  color: #1b61a1;
  font-weight: lighter !important;
}
.arrow a:hover {
  text-decoration: none;
  list-style-type: none;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}
.navbar-toggler {
  padding: 0.25rem 0.5rem !important;
  font-size: 1rem !important;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.modal-content {
  margin-top: 100px;
}
/*-------------Media Queries-------------------*/

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
  .slider-wrapper2,
  .slide {
    height: calc(50vh);
  }
}

@media (max-width: 640px) {
  .About {
    padding-left: 20px;
    padding-right: 20px;
  }
  .paragraphblue {
    font-size: 23px;
    color: #1b62a1;
    line-height: 40px;
    text-align: justify;
    padding-top: 20px;
    padding-right: 0px;
  }
  .paragraphgray {
    font-size: 20px;
    color: #202227;
    line-height: 35px;
    text-align: justify;
    padding-top: 8px;
    padding-right: 0px;
  }
  svg:not(:root).svg-inline--fa {
    margin-top: -30px !important;
  }
  .pdngtp {
    padding-top: 0px !important;
  }
  .endexpl {
    padding-left: 30px;
  }
  .slider-wrapper2,
  .slide {
    height: calc(80vh);
  }
  .slider-wrapper11 > .previousButton {
    display: block;
    margin-left: -30px;
  }
  .paytopp {
    padding-top: 20px;
    padding-bottom: 50px;
  }
  .clientlogopdng {
    padding-top: 10px;
    padding-bottom: 50px;
  }
  .reviews_p1 {
    padding: 2px 10px 2px 0px;
  }
  .reviewLpdng_0 {
    padding: 10px 0 10px 50px;
  }
  .header {
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #fff;
    position: fixed;
    width: 100%;
    height: auto;
  }
  .review_bg {
    background-color: #e5e9f2;
    padding: 30px 30px 10px 30px;
    margin-top: 0px;
    min-height: 700px;
  }
  .review_slider {
    min-height: 800px !important;
  }
  .review_hdr {
    font-size: 30px;
    text-align: center !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .reviews_p {
    line-height: 23px;
  }
  .reviews_p0 {
    padding: 10px 10px 10px 0px;
    line-height: 23px;
  }
  .Review_profileimg {
    width: 60px;
    height: 60px;
    padding-top: 0px;
    padding-left: 5px;
    position: absolute;
    top: -230px;
    left: 290px;
    /* display: inline-block; */
    z-index: 990;
  }
  .Review_profileimg1 {
    width: 60px;
    height: 60px;
    padding-top: 0px;
    padding-left: 5px;
    position: absolute;
    top: -230px;
    left: 290px;
    display: inline-block;
    z-index: 990;
  }
  .revislide2 {
    padding-left: 50px;
  }
  .OurClients_bg {
    min-height: 400px;
    padding: 0px 30px 10px 30px;
    margin-top: 0px;
  }
  .ourclient_hdr {
    font-size: 30px;
    padding-top: 15px;
    padding-bottom: 25px;
  }
  .ourclient_hdr1 {
    font-size: 30px;
    padding-top: 15px;
    padding-bottom: 25px;
  }
  .testimonialsbg {
    min-height: 450px;
    padding-bottom: 50px;
  }
  .logoalign {
    text-align: left;
    padding-left: 60px;
  }
  .whatwedo_hdrsub,
  .whatwedo_hdrsub1 {
    padding-top: 50px;
    padding-left: 0;
    padding-right: 0;
  }
  .Design_tpdng {
    margin-top: 25px;
    padding: 0px !important;
  }
  .card-body {
    padding: 0rem;
  }
  .popupimg {
    padding-bottom: 100px;
    padding-top: 10px;
  }
  .btntmng {
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 0;
  }
  .innerContent {
    min-height: 500px;
    padding: 30px 10px 50px 10px;
    background-color: #fff;
  }
  .content_mrg {
    margin-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .content_mrg1 {
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .pay_pdng {
    padding-left: 10px;
    padding-right: 10px;
  }
  .box1,
  .box2,
  .box3,
  .box4 {
    border-right: 0;
  }
  .borderb1 {
    border-bottom: solid 1px #e0e0e0;
  }
  .cardpdng_img {
    padding-left: 0px;
    padding-top: 50px;
    text-align: center;
  }
  .navbar {
    padding: 0rem 0rem !important;
  }
  .addresstopp {
    padding-top: 10px;
    padding-left: 0px;
  }
  .address_hdr {
    margin-top: 20px;
  }
  .contactfrom {
    background-color: #fff;
    padding: 20px 0px 30px 0px;
    margin-top: 0px;
    margin-bottom: 50px;
    opacity: 1;
  }
  .ind_line {
    height: 220px;
    margin-top: 40px;
    border-left: solid 6px #66b3d8;
  }
  .slider-wrapper,
  .slide {
    height: calc(80vh - 200px);
  }
  .boxpdng {
    margin-top: 0px;
  }
  .boxpdng1 {
    margin-top: 10px;
  }
  .boxpdng4 {
    margin-top: 10px;
  }
  .getintbg1 {
    margin-top: 5px;
    padding-left: 5px;
  }
  .slider-content .inner {
    padding: 0 10px;
  }
  /* .content_mrg {
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
  } */
  .getintbg,
  .getintbg1 {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .whatwedo_pdng {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .Build_tpdng {
    margin-top: 10px;
    padding: 5px !important;
  }
  .Secure_tpdng {
    margin-top: 10px;
    padding: 5px !important;
  }
  .cardbr {
    height: 30px;
    width: 50px;
  }
  .cardpdng {
    padding-left: 10px;
    padding-top: 20px;
  }
  .centerpdng {
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .testimonialspanel {
    padding: 0px 10px 10px 10px;
    margin-top: 0px;
    background-color: #0a4f8d;
    background-image: none;
    /* background: #0e5b9f url(/assets/testbg.png) no-repeat; */
    background-size: contain;
    background-position: center;
    background: cover;
    min-height: 450px;
    border-radius: 10px;
  }
  .profileimg {
    width: 170px;
    height: 170px;
  }
  .heading1,
  .heading_testiml {
    padding-left: 0;
  }
  .testimonial_p,
  .testimonial_sub {
    line-height: 20px;
    font-size: 16px;
    color: #fff;
    text-align: left;
    line-height: 25px;
    padding: 10px 10px 10px 0px;
  }
  .testimonial_p {
    padding: 80px 30px 0 30px;
  }
  .hdr1_pdng {
    padding-left: 0px;
    padding-top: 270px;
    margin-top: 60px;
    padding-bottom: 10px;
  }
  .btntopm5 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .trustedbyindpanel {
    margin-top: 0px;
    background-color: #f3f3f3;
    background-image: none;
    border: solid 1px #b8ddff;
    /* background: url(/assets/testimonials-logosbg.png) no-repeat; */
    min-height: 465px;
    border-radius: 65px 20px 20px 20px;
    -moz-border-radius: 65px 20px 20px 20px;
    -webkit-border-radius: 65px 20px 20px 20px;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(117, 117, 117, 1);
    -moz-box-shadow: 0px 3px 5px 0px rgba(117, 117, 117, 1);
    box-shadow: 0px 3px 5px 0px rgba(117, 117, 117, 1);
  }
  .testimonial_sub {
    color: #2f2f2f;
  }
  .trustDivpdng {
    padding: 50px 10px 10px 15px;
  }
  .NewsDivbg {
    background-color: #135ca0;
    padding: 5px 50px 100px 20px;
    background: url(/assets/newsbg.png) no-repeat;
    background-position: right;
    background: cover;
  }
  .Contactbannerhdr {
    padding: 20px;
  }
  .caretojoin {
    font-size: 40px;
  }
  .About_hdr,
  .About_hdr1,
  .About_hdr2,
  .About_hdr3 {
    text-align: center;
  }
  .Contactbannerhdr,
  .Contactbannerhdr1,
  .Contactbannerhdr2,
  .Contactbannerhdr4 {
    text-align: center;
    padding: 15px;
  }
  .para1 {
    padding-right: 10px;
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
  .h1_hdr {
    font-size: 30px !important;
    text-align: left;
    line-height: 35px;
  }
  .slider-content .inner p {
    font-size: 16px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .h1_hdr {
    font-size: 30px !important;
    text-align: left;
    line-height: 35px;
  }
  .slider-content .inner p {
    font-size: 16px;
  }
  .slider-content .inner {
    padding: 0 60px;
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    top: 55%;
    left: 46%;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: 0 !important;
    z-index: 99999999;
    position: absolute;
    right: 0;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* For portrait layouts only */
  .roundedbox,
  .roundedbox1,
  .roundedbox2,
  .roundedbox3 {
    margin: 5px;
    width: 120px;
    height: 130px;
  }
  .clientNo,
  .clientNo1,
  .clientNo2,
  .clientNo3,
  .clientNo4,
  .clientNo5,
  .clientNo6,
  .clientNo7,
  .clientNo03 {
    font-size: 14px;
  }
  .clientNo1,
  .clientNo3,
  .clientNo4,
  .clientNo5,
  .clientNo6,
  .clientNo7 {
    padding-top: 35px;
  }
}
.pdngtp {
  padding-top: 40px;
}

.background-img {
  height: 230px;
  width: 400px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 5% auto;
  padding: 10px;
  border: 1px solid #2a3cad;
  border-radius: 4px;
  box-shadow: 0px 0px 5px #2a3cad;
  position: relative;
}

.content h2 {
  font-size: 19px;
  padding-top: 50px;
}
.content h2 a {
  color: #fff;
}

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 180px;
  background: #003a78;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 20px 50px rgb(23, 32, 90);
  border: 2px solid #2a3cad;
  color: white;
  padding: 20px;
}

.box:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.5s;
  pointer-events: none;
}

.box:hover:before {
  left: -50%;
  transform: skewX(-5deg);
}

.box .content {
  position: absolute;
  top: 5px;
  left: 0px;
  right: 15px;
  bottom: 15px;
  padding: 15px;
  text-align: center;
}

.box span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
}

.box span:nth-child(1) {
  transform: rotate(0deg);
}

.box span:nth-child(2) {
  transform: rotate(90deg);
}

.box span:nth-child(3) {
  transform: rotate(180deg);
}

.box span:nth-child(4) {
  transform: rotate(270deg);
}

.box span:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  background: #50dfdb;
  animation: animate 4s linear infinite;
}

@keyframes animate {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  50.1% {
    transform: scaleX(1);
    transform-origin: right;
  }

  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}

/* .menuactive{
    color: #fff !important;
    background-color: #ff0000 !important;
} */

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #fff !important;
  background-color: #1b62a1 !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
/* .navbar-light a .navbar-nav a .nav-link a{
      background-color: #ffd800 !important;
      color: #fff !important;
  } */
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  background-color: #1b62a1 !important;
  color: #fff !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.navbar-light .navbar-nav .nav-link:focus {
  background-color: #1b62a1 !important;
  color: #fff !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.pricing-title {
  padding-top: 50px;
  text-align: left !important;
}
.pricing-title h3 {
  padding-top: 20px;
  font-size: 40px;
  color: #0e5b9f;
  text-align: left !important;
}
.pricing-title h5 {
  padding-top: 10px;
  font-size: 28px;
  text-align: left !important;
}
/*-----Pay Css------*/
.payicons {
  font-size: 35px;
  color: #fff;
}
.paybg {
  padding-top: 10px;
  padding-bottom: 50px;
  background-color: #f3f4f5;
}
.planspadng {
  padding-left: 10px;
  padding-top: 30px;
  padding-bottom: 10px;
}
.price-gd-top {
  text-align: center;
}
.price-gd-top h4 {
  font-size: 1.8em;
  color: #fff;
  padding: 10px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.prohdr {
  color: #3da5d6 !important;
}
.hdrh3 {
  color: #ff9b8c !important;
}
.upto1 {
  color: #eb984f !important;
}
.darkgry {
  color: #80624d !important;
}
.price-gd-top h3 {
  padding: 0em 0em 0 20px;
  font-size: 3.5em;
  color: #ffd700;
}
.price-gd-top h3 span {
  font-size: 30px;
  vertical-align: top;
}
.price-gd-top h5 {
  font-size: 20px;
  line-height: 26px;
  color: #fff;
  padding: 0em 0em 0.8em 0em;
  text-align: center;
  text-transform: capitalize;
}
.price-block {
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  /* background: #000; */
  padding: 20px;
}
/* .price-block:hover,.w3layouts-pricing:hover,.w3-agileits-vpn-grid:hover {
    transform: scale(1.06);
    -webkit-transform: scale(1.06);
    -moz-transform: scale(1.06);
    -o-transform: scale(1.06);
    -ms-transform: scale(1.06);
    z-index: 1;
} */
.price-grid {
  width: 25%;
  float: left;
  padding: 6px;
}
.plans-main {
  width: 100%;
  margin: 0 auto;
}
.price-block {
  /* background: #146794;
    background: rgba(20, 103, 148);     */
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
}
.price-block0 {
  padding: 20px;
  background: #146794;
  background: rgba(20, 103, 148);
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
}
.price-block02 {
  padding: 20px;
  border: solid 2px #3da5d6;
  border-radius: 10px 10px 10px 10px;
  -moz-border-radius: 10px 10px 10px 10px;
  -webkit-border-radius: 10px 10px 10px 10px;
}
.price-block1 {
  border: solid 2px #e8a680;
  /* background: #2975b0;
    background: rgba(41, 117, 176); */
}
.price-block2 {
  /* background: #3b8abe;
    background: rgba(59, 138, 190); */
  border: solid 2px #3da5d6;
}
.price-block3 {
  background: #66b3d8;
  background: rgba(102, 179, 216);
}
.upto {
  font-size: 18px !important;
  color: #fff;
  padding: 0;
  margin: 0;
}
/* pricing tables */

@media (max-width: 640px) {
  .price-grid {
    width: 100%;
  }
}

.blog_hdr {
  background-color: #1b61a1;
  padding-top: 6px;
  padding-bottom: 6px;
}
.blogNav ul {
  padding: 0px 0px 0px 50px;
  margin: 0;
}
.blogNav ul li {
  padding: 10px 10px 10px 10px;
  margin: 0;
  float: left;
  list-style-type: none;
  text-decoration: none;
}
.blogNav ul li a {
  padding: 0;
  margin: 0;
  color: #fff;
  float: left;
  list-style-type: none;
  text-decoration: none;
}

.bloglogop {
  padding-top: 2px;
  text-align: left;
}
.bloglogop1 {
  padding-top: 20px;
  text-align: left;
}
.blogicons {
  padding-top: 5px;
  padding-left: 10px;
  text-align: left;
}
.blogNavbor {
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
}
.blogNavLink ul {
  padding: 0px 0px 0px 50px;
  margin: 0;
}
.blogNavLink ul li {
  padding: 10px 75px 0px 75px;
  margin: 0;
  float: left;
  list-style-type: none;
  text-decoration: none;
}
.blogNavLink ul li a {
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #1b61a1;
  font-weight: bold;
  float: left;
  line-height: 20px;
  padding-bottom: 10px;
  list-style-type: none;
  text-decoration: none;
}
.blogtophdr {
  font-size: 30px;
  color: #003a78;
  padding-top: 10px;
  padding-bottom: 15px;
  text-align: center;
  font-weight: bold;
}
.bloghdr {
  font-size: 30px;
  color: #003a78;
  padding-top: 10px;
  text-align: left;
  font-weight: bold;
}
.blogmrgt20 {
  margin-top: 10px;
}
.blogbanner {
  padding: 30px 10px 30px 10px;
  margin-top: 10px;
  width: 100%;
  background-color: #003a78;
  border-radius: 15px;
  background: url(/assets/blog-banner.jpg) no-repeat;
  background-size: contain;
  background-position: center;
  background: cover;
  min-height: 465px;
}
.bloghdr1 {
  font-size: 30px;
  color: #003a78;
  padding-top: 10px;
  text-align: left;
  font-weight: 400;
  line-height: 32px;
  margin-top: 25px;
}
.blogtpara {
  font-size: 16px;
  text-align: left;
  padding: 10px 10px 10px 20px;
  line-height: 22px;
}
.blogpara {
  font-size: 16px;
  text-align: left;
  padding: 10px 10px 0px 0;
  line-height: 22px;
}
.subscribe {
  margin-top: 50px;
  padding-left: 0px;
}
.bloghdr2 {
  font-size: 23px;
  color: #003a78;
  padding-top: 0px;
  text-align: left;
}
.bloghdr3 {
  font-size: 16px;
  color: #26292a;
  padding-top: 5px;
  text-align: left;
}
.blogbtnbr {
  padding: 5px 0 0 15px;
  margin-bottom: 10px;
  border-bottom: solid 2px #ccc;
}
.imgmb {
  margin-bottom: 10px;
}
.cardbox {
  width: 16rem;
  float: left;
  padding-left: 10 !important;
}
.cardl0 {
  padding-left: 0;
}
.cardpara {
  font-size: 16px;
  text-align: left;
  padding: 5px 0px 0px 0;
  line-height: 22px;
}
.cardtitle {
  font-size: 18px !important;
  font-weight: bold;
  text-align: left;
  padding: 0;
  margin: 0;
}
.cardbody {
  padding: 0rem !important;
}
.blogmrgt10 {
  margin: 10px 0px 10px 0px;
}
.margb50 {
  margin-bottom: 50px;
}
.subbg {
  background-color: #f3f5f6;
  padding: 50px;
}
.emailinput {
  border-radius: 30px;
}
.subscribebg {
  border-radius: 30px;
  background: #387498;
  padding-left: 20px;
  margin-left: 10px;
  padding-right: 20px;
  border-color: #004066;
}
